<template>
    <v-container class="mx-auto" >
        <v-toolbar-title class="text-color-title text--darken-4 text-xs-subtitle-2 text-lg-h5 text-wrap text-left">
                      Receta médica
        </v-toolbar-title>

        <v-card outlined :loading="loading" class="mt-5">
            <template slot="progress">
                 <v-progress-linear
                   color="cyan"
                   indeterminate
                   stripped
                 ></v-progress-linear>
               </template>
            <v-card-title class="text-h6 text-wrap">
            Agregar medicamento
            </v-card-title>
            <v-card-text>
            <remote-search ref="rs" :url="url" label="Especifique el medicamento a recetar" min="4" placeholder="Busque y seleccione el medicamento a recetar" @change="selected" :text="'medicamento'" :icon="icono"></remote-search>

            <v-form ref="form"
                v-model="valid"
                :lazy-validation="false"
            >
                <v-text-field ref="dosis" v-model="dosis" label="Dosis*" required :rules="drules"></v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined color="success" @click="agregar" :disabled="!(valid&&medicamentoSeleccionado)" >Agregar</v-btn>
            </v-card-actions>
            <v-card-text>
            <v-divider></v-divider>
            <div class="overline mb-4"><v-icon color="success">mdi-pill</v-icon> Medicamentos recetados</div>

            <v-list two-line style="overflow-y:auto; min-height=48px; max-height:240px;" dense>


                      <v-list-item-group v-model="selectedItem" active-class="light-blue lighten-5">
                        <v-list-item  two-line v-for="(item,i) in items"
                            :key="'med_'+i">
                            <v-list-item-content>
                                <span class="subtitle-2">{{item.medicamento}}</span>
                                <span class="body-2">{{item.dosificacion}}</span>
                            </v-list-item-content>
                            <v-list-item-action class="pr-3">
                                <v-btn fab x-small dark color="cyan" v-if="i>0" @click="swap(i)" title="Mover arriba">
                                    <v-icon >mdi-arrow-up-bold</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-btn fab x-small dark color="green lighten-1" title="Editar dosis" @click="actualizarDosis(i)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-btn fab x-small dark color="red lighten-1" @click="indexMed=i;delMedDiag=true" title="Eliminar medicamento" :v-if="!isDeleting(item)" :loading="isDeleting(item)">
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </v-list-item-action>

                            <!--v-list-item-action>
                                                            <v-btn icon v-if="i>0" @click="swap(i)">
                                                                <v-icon small color="cyan" >mdi-swap-vertical</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                        <v-list-item-action>
                                                            <v-btn icon title="Editar dosis">
                                                                <v-icon small color="green lighten-1">mdi-pencil</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action>
                                                        <v-list-item-action>
                                                            <v-btn icon @click="indexMed=i;delMedDiag=true" title="Eliminar medicamento" :v-if="!isDeleting(item)" :loading="isDeleting(item)">
                                                                <v-icon small color="red lighten-1">mdi-trash-can-outline</v-icon>
                                                            </v-btn>
                                                        </v-list-item-action-->


                        </v-list-item>
                </v-list-item-group>
                    </v-list>
            </v-card-text>

        <v-card-text>
        <v-spacer></v-spacer>
                <v-textarea
                                ref="indicaciones"
                                class="mt-2"
                                height="100"
                                name="indicaciones"
                                label="Indicaciones generales que aparecen en la receta"
                                placeholder="Escribe aquí las indicaciones generales que aparecen en la receta"
                                v-model="indicaciones"
                                @change="guardarIndicaciones"
                                outlined
                                title="Las indicaciones generales aparecen en la receta"
                            ></v-textarea>

        </v-row>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="generarReceta" :disabled="!((valid&&medicamentoSeleccionado) ||items.length > 0 || (indicaciones && indicaciones.length > 0))" :loading="generating">Generar receta</v-btn>

        </v-card-actions>



        </v-card>
        <confirm v-if="delMedDiag" @cancel="delMedDiag=false" @confirm="delMedDiag=false; eliminarMedicamento()"></confirm>

        <v-overlay  :value="showRx" opacity="0.99">
            <object :data="rx && rx != null" type="application/pdf" width="700" height="500" class="mt-2">
                <a :href="rx">Descargar receta</a>
            </object>



            <div class="text-center">
                <v-btn class="mx-2" outlined dark small color="info" @click="close">Cerrar</v-btn>
            </div>
            <span class="caption">Si el paciente tiene e-mail registrado, la receta ya le fue enviada</span>
        </v-overlay>

        <v-dialog v-model="updateDosisDialog" light :persistent="true" max-width="800">
                <edit-dosis :c="citaId" :t="treatmentSelected" @cancel="updateDosisDialog=false" @updated="dosisUpdated" ></edit-dosis>
        </v-dialog>
    </v-container>
</template>

<script>
import CitaService from "@/services/cita.service.js";

export default {
    components:
    {
        'remote-search': () => import("@/components/RemoteSearch"),
        Confirm: () => import("@/components/Confirm"),
        'edit-dosis':() => import("@/components/EdicionDosisMedicamento")

    },
    data:()=>({
        pacienteId: undefined,
        citaId:undefined,
        dosis:null,
        valid:false,
        icono: { key: 'reaccionAlergica', name: 'mdi-skull-crossbones', title: 'Puede generar reacciones alérgicas.', color: 'orange lighten-1'},
        found: 0,
        drules: [
            v => (!!v&&(v.length>10||/^([0-1]-[0-1]-[0-1]){1}$/.test(v)&&(v.trim()!='0-0-0')))||'Completar dosis',
            v => (!!v && v.length < 128)||'Máximo 128 caracteres'
        ],
        items:[],
        indicaciones: null,
        maxCarateresCampoTexto: 128,
        medicamentoSeleccionado:null,
        selectedItem:undefined,
        loading:true,
        generating:false,
        delMedDiag:false,
        indexMed:-1,
        borrando: [],
        rx:null,
        updateDosisDialog:false,
        treatmentSelected:null,
        icono: { key: 'reaccionAlergica', name: 'mdi-skull-crossbones', title: 'Puede generar reacciones alérgicas.', color: 'orange lighten-1'},
    }),
    computed:{
        url:function()
        {
            return '/api/cita/receta/medicamentos/'+this.pacienteId+ '/';
        },
        showRx:function(){
            return this.rx != null;
        },

    },
    methods:{
        actualizarDosis:function(idx){
            this.treatmentSelected = this.items[idx];
            this.updateDosisDialog = true;
        },
        dosisUpdated:function(item){
            let at = this.items.findIndex(function(t){
                            return t.tid===item.tid;
            });

            if(at >= 0){
                this.$nextTick(() => {
                    this.items[at] = item;
                });
            }
            this.updateDosisDialog=false;
        },
        isDeleting: function(item) {
            return this.borrando.indexOf(item) > -1;
        },
        selected:function(med){
            this.medicamentoSeleccionado=med;
            this.$refs.dosis.focus();
        },
        agregar:function(){
            this.$refs.form.validate();
            if(this.valid){
                this.guardarMedicamento(this.medicamentoSeleccionado, this.dosis);
            }
        },
        reset:function(){
            this.medicamentoSeleccionado=null;
            this.dosis = null;
            this.$refs.dosis.reset();


        },
        eliminarMedicamento:function(){
            if(this.indexMed >= 0){
                let medicamento = this.items[this.indexMed];
                if (this.borrando.indexOf(medicamento) == -1) {
                            this.borrando.push(medicamento);
                } else {
                            return;
                }


                CitaService.deleteTratamiento(this.citaId, medicamento.mid, medicamento.origen).then((data)=>{
                    if(data && data > 0){
                        var cp = this.items;
                        cp.splice(cp.indexOf(medicamento), 1);
                        this.$nextTick(() => {
                            this.items = cp;
                        });
                    }
                    else{
                        this.$root.showMessage('No fue posible eliminar el medicamento', 'error');
                    }


                });
            }


        },
        guardarIndicaciones:function(){


            if(this.indicaciones && this.indicaciones.length > 0){
                const params = {citaId:this.citaId, value:this.indicaciones, pacienteId:this.pacienteId, cname:'indicaciones'};
                CitaService.saveVitalSign(params).then((data)=>{
                    if(data){
                        this.$root.showMessage('Las indicaciones médicas han sido guardadas', 'info');
                    }
                    else{
                        this.$root.showMessage('No se pudieron guardar las indicaiones médicas', 'error');
                    }
                });
            }

        },
        guardarMedicamento:function(med,dosis){
            let at = this.items.findIndex(function(t){
                return t.mid===med.mid&&t.origen===med.origen;
            });
            if(at<0){
                const params={c:this.citaId, m:med.mid,o:med.origen,dosis:dosis};
                CitaService.saveTratamiento(params).then((data)=>{
                    if(data&&data>0){
                        //Requiero guardar en items;
                            med.dosificacion=dosis;
                            med.tid=data;
                            this.items.push(med);
                            this.selectedItem = this.items.length - 1;
                        this.$root.showMessage('Medicamento agregado','success');
                        this.$refs.rs.clear();
                    }
                    else{
                        this.$root.showMessage('El medicamento no pudo ser agregado', 'error');
                    }



                }).then(function () {
                    this.reset();
                }.bind(this));

            } else {
                this.reset();
                this.$root.showMessage("El medicamento ya se encuentra registrado", "warning");

            }
        },
        generarReceta:function(){
            if((this.items && this.items.length > 0) || (this.indicaciones && this.indicaciones.trim.length > 0)){
                this.$root.showMessage('Si el paciente tiene e-mail registrado, se le enviará la receta a esa dirección','info');
                this.generating = true;
                CitaService.generarRx(this.citaId).then((data)=>{

                    if(data && data.filename){
                        this.ver(data.filename);
                    }
                    else{
                        this.$root.showMessage('Algo pasó que la receta no se pudo generar. Intenta de nuevo', 'warning');
                    }
                }).then(function(){this.generating = false;}.bind(this));

            }
        },
        close:function(){
            this.rx = null;
        },
        ver:function(filename){
            //this.rx = 'http://192.168.1.91:8080/MedicalManik/interop/receta/descargar/'+filename;

            window.open(process.env.VUE_APP_MEDICAL_MANIK_URL+'/interop/receta/descargar/'+filename);
        },
        swap:function(i){
            if(i>0){
                const params={i2:this.items[i].tid,i1:this.items[i-1].tid};
                CitaService.changeTheOrderOfTreatment(params).then((data)=>{
                    if(data && data == 2){
                        const i1 = this.items.findIndex(function(t){
                            return t.tid==params.i1;
                        });
                        const i2 = this.items.findIndex(function(t){
                            return t.tid==params.i2;
                        });
                        const e2 = this.items.splice(i2,1); // primero el más lejano
                        const e1 = this.items.splice(i1,1,e2[0]); // primero el más cercano

                        this.$nextTick(() => {
                            this.items.splice(i2,0,e1[0]);
                        });

                    }
                    else{
                        this.message('No se pudo cambiar el orden del medicamento','warning')
                    }
                });
            }
        }
    },
    beforeMount(){
        this.pacienteId = this.$route.params.pacienteId;
        this.citaId = this.$route.params.tareaId;
        if(typeof this.pacienteId === "undefined" ||  typeof this.citaId === "undefined"){
                    //¿debo retornar a la página de agenda?
        }

        CitaService.getInfoReceta(this.citaId).then((data)=>{
            if(data){
                let tratamiento = data.tratamiento;
                let indicaciones = data.indicaciones;

                if(tratamiento){
                    this.items = tratamiento;
                }

                if(indicaciones){
                    this.indicaciones = indicaciones;
                }
            }

        }).then(function(){
            this.loading=false;
        }.bind(this));
    }
}
</script>