import axios from "axios";
import { Urls } from "@/consts.js";

const CitaService = {
    getVitalsSigns:function(citaId){
        return axios.get(Urls.CITA_URL+ "/vitalsigns/"+citaId);
    },
    saveVitalSign:function(params){
        return axios.put(Urls.CITA_URL+"/vitalsigns", params);
    },
    getBloodGlucoseLevels:function(citaId){
        return axios.get(Urls.CITA_URL+"/glucose/"+citaId);
    },
    registerBloodGlucoseLevel:function(citaId, params){
        return axios.put(Urls.CITA_URL+"/glucose/add/"+citaId, params);

    },
    updateBloodGlucoseLevel:function(params){
        return axios.put(Urls.CITA_URL+"/glucose/update", params);
    },
    deleteBloodGlucoseLevel:function(id){
        return axios.delete(Urls.CITA_URL+"/glucose/del/"+id);
    },
    getInfoReceta:function(citaId){
        return axios.get(Urls.CITA_URL+"/receta/info/"+citaId);
    },
    saveTratamiento:function(params){
        return axios.post(Urls.CITA_URL+"/receta/tratamiento", params)
    },
    saveIndicaciones:function(params){
        return axios.put(Urls.CITA_URL+"/rx/indicaciones/", )
    },
    deleteTratamiento:function(citaId, medicamentoId, origen){
        return axios.delete(Urls.CITA_URL+"/rx/delmed/"+citaId+"/"+medicamentoId+"/"+origen);
    },
    generarRx:function(citaId){
        return axios.put(Urls.CITA_URL+"/rx/generar/"+citaId);
    },
    changeTheOrderOfTreatment:function(params){
        return axios.put(Urls.CITA_URL+"/rx/medicamentos/swap", params);
    },
    updateDosis:function(params){
        return axios.put(Urls.CITA_URL+"/rx/tratamiento/dosis", params);
    }


};

export default CitaService;